import React, { Fragment } from "react";
import Loadable from "react-loadable";
import { Loading } from "../ui/Loading";
import { Home } from "../Home";
import Route from "react-router-dom/Route";
import { ProtectedRoute } from "./ProtectedRoute";

const AsyncEventGenerator = Loadable({
  loader: () => import("../../event-generator/EventGenerator"),
  loading: Loading
});

const AsyncCreateUser = Loadable({
  loader: () => import("../create-user/CreateUser"),
  loading: Loading
});

const AsyncLogin = Loadable({
  loader: () => import("../auth/Login"),
  loading: Loading
});

export const Routes = () => (
  <Fragment>
    <Route exact path="/" component={Home} />
    <Route path="/login" component={AsyncLogin} />
    <Route path="/event-generator" component={AsyncEventGenerator} />
    <ProtectedRoute path="/create-user" component={AsyncCreateUser} />
  </Fragment>
);
