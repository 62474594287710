import React from "react";
import { Route } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Redirect from "react-router-dom/Redirect";

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <UserContext.Consumer>
    {user => (
      <Route
        {...rest}
        render={props =>
          user.user.auth_token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )}
  </UserContext.Consumer>
);
