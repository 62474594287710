import React, { Component } from "react";
import { Header } from "./components/Header";
import { Content } from "./components/Content";
import { BrowserRouter as Router } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import { get } from "./api/httpMethods";
import { BaseUrl } from "./global/Environments";
import { getAuthHeaders } from "./api/httpHeaders";
import { Routes } from "./components/routing/Routes";
import { Theming } from "./components/ui/Theming";
import { Internationalize } from "./components/i18n/Internationalize";

class App extends Component {
  getOperators = () => {
    const { user } = this.state;
    return get(BaseUrl(`admin/operators`), getAuthHeaders(user.auth_token));
  };

  addUserToState = user => {
    this.setState({ user, loggedIn: true }, async () => {
      const { operators } = await this.getOperators();
      this.setState({ operators });
    });
  };

  removeUserFromState = () => {
    this.setState({ user: {}, loggedIn: false, operators: null });
  };

  setUser = user => {
    this.addUserToState(user);
    localStorage.setItem("kpUser", JSON.stringify(user));
  };

  removeUser = history => {
    this.removeUserFromState();
    localStorage.removeItem("kpUser");
    history.push("/");
  };

  componentDidMount() {
    const data = localStorage.getItem("kpUser");
    if (data) {
      const user = JSON.parse(data);
      this.addUserToState(user);
    }
  }

  state = {
    user: {},
    setUser: this.setUser,
    loggedIn: false,
    removeUser: this.removeUser
  };

  render() {
    return (
      <Theming>
        <Internationalize>
          <UserContext.Provider value={this.state}>
            <Router>
              <div>
                <Header />
                <Content>
                  <Routes />
                </Content>
              </div>
            </Router>
          </UserContext.Provider>
        </Internationalize>
      </Theming>
    );
  }
}

export default App;
