import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00cc99",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#FFFFFF"
    }
  }
});

export const Theming = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
