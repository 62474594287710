import React from "react";
import { Text } from "./i18n/TranslatedText";
import styled from "styled-components";
import Link from "react-router-dom/Link";

const Title = styled.h2`
  color: #00cc99;
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  li {
    padding: 8px 0px;
  }
`;

export const Home = () => (
  <div>
    <Title>
      <Text text="home.title" />
    </Title>
    <p>
      <Text text="home.intro" />
    </p>
    <List>
      {process.env.REACT_APP_KEYPER_ENVIRONMENT === "sandbox" && (
        <li>
          <Link to="/create-user">
            <Text text="menu.create_user" />
          </Link>
        </li>
      )}
      <li>
        <Link to="/event-generator">
          <Text text="menu.create_tickets" />
        </Link>
      </li>
    </List>
  </div>
);
