import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
  justify-content: center;
`;

export const Loading = () => (
  <Container>
    <CircularProgress size={32} />
  </Container>
);
