import React, { Component } from "react";
import { TranslationContext } from "../../context/TranslationContext";

export class Internationalize extends Component {
  state = { locale: "de", translations: {} };

  async componentDidMount() {
    const { locale } = this.state;
    try {
      const translations = await import(`../../translations/${locale}.json`);
      this.setState({ translations });
    } catch (error) {
      console.error(
        `Translation file for locale "${locale}" could not be found!`
      );
    }
  }

  render() {
    const { children } = this.props;
    return (
      <TranslationContext.Provider value={this.state}>
        {children}
      </TranslationContext.Provider>
    );
  }
}
