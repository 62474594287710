import React from "react";

export const UserContext = React.createContext({
  user: {},
  loggedIn: false,
  setUser: () => {},
  removeUser: () => {}
});

export const withUser = Component => props => (
  <UserContext.Consumer>
    {user => <Component {...props} user={user} />}
  </UserContext.Consumer>
);
