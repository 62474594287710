import { AppSecret } from "../global/AppSecret";

export const DefaultHeaders = new Headers({
  "Content-Type": "application/json",
  authorization: `APPSECRET app_secret="${AppSecret}"`
});

export const getAuthHeaders = token =>
  new Headers({
    "Content-Type": "application/json",
    authorization: `AUTHTOKEN auth_token="${token}"`
  });
