import React from "react";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "react-router-dom/Link";
import { Text } from "./i18n/TranslatedText";
import { IconButton } from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ExitApp from "@material-ui/icons/ExitToApp";
import LockOpen from "@material-ui/icons/LockOpen";
import ticket from "../img/ticket.svg";
import { withUser } from "../context/UserContext";
import withRouter from "react-router-dom/withRouter";

const Container = styled.header``;

const Title = styled.h3`
  flex: 1;
`;

const TitleLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: white;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  color: white;
  svg {
    fill: white;
  }
`;

const HeaderComponent = ({ user, history }) => (
  <Container>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Title>
          <TitleLink to="/">
            <Text text="title" />
          </TitleLink>
        </Title>
        {process.env.REACT_APP_KEYPER_ENVIRONMENT === "sandbox" && (
          <IconButton component={Link} to="/create-user" color="secondary">
            <PersonAdd />
          </IconButton>
        )}
        <IconButton component={Link} to="/event-generator" color="secondary">
          <Image src={ticket} />
        </IconButton>
        {user.loggedIn ? (
          <IconButton
            color="secondary"
            onClick={() => user.removeUser(history)}
          >
            <ExitApp />
          </IconButton>
        ) : (
          <IconButton component={Link} to="/login" color="secondary">
            <LockOpen />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  </Container>
);

export const Header = withRouter(withUser(HeaderComponent));
