import { DefaultHeaders } from "./httpHeaders";

export const post = (url, body, headers = DefaultHeaders) =>
  fetch(url, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify(body),
    headers
  }).then(response => {
    if (response.ok) {
      return response
        .json()
        .then(response => response)
        .catch(error => {});
    }
    return Promise.reject();
  });

export const get = (url, headers) =>
  fetch(url, {
    method: "GET",
    cache: "no-cache",
    headers
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 401) {
      localStorage.removeItem("session");
    }
    return Promise.reject();
  });
