import React from "react";
import { TranslationContext } from "../../context/TranslationContext";

const getTranslation = ({ translations }, text) => {
  if (translations[text]) {
    return translations[text];
  }
  return "Translation not found";
};

export const Text = ({ text }) => (
  <TranslationContext.Consumer>
    {translations => <span>{getTranslation(translations, text)}</span>}
  </TranslationContext.Consumer>
);
