export const Environments = {
  Test: "test",
  Develop: "develop",
  Staging: "staging",
  Sandbox: "sandbox"
};

export const getApiUrl = () => {
  switch (process.env.REACT_APP_KEYPER_ENVIRONMENT) {
    case Environments.Test: {
      return "https://test.api.keyper.io";
    }
    case Environments.Develop: {
      return "https://develop.api.keyper.io";
    }
    case Environments.Staging: {
      return "https://staging.api.keyper.io";
    }
    default: {
      return "https://sandbox.api.keyper.io";
    }
  }
};

export const BaseUrl = url => `${getApiUrl()}/${url}`;
